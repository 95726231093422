var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"news-teaser--ecm news-teaser--row",attrs:{"href":_vm.item.url ? _vm.item.url : _vm.localePath(`/${_vm.path}/${_vm.item.slug}/${_vm.item.pk_id}`),"data-cy":"newsTeaser","data-matomo-category":"SelectNews","data-matomo-action":"Read_News","data-matomo-name":"Beiträge","data-matomo-value":_vm.itemTitle},on:{"click":_vm.triggerMatomoEvents}},[((_vm.item.titelbild && _vm.item.titelbild.id) || _vm.item.imageurl)?_c('img',{staticClass:"news-teaser__image u-rounded-1",attrs:{"height":"189","width":"300","src":(_vm.item.titelbild && _vm.item.titelbild.id)
        ? _vm.$directusApi.imagePath(_vm.item.titelbild.id, {
            fit: 'cover',
            width: _vm.asRow ? 560 : 300,
            height: _vm.asRow ? 353 : 189,
          })
        : _vm.item.imageurl,"alt":(_vm.item.titelbild && _vm.item.titelbild.copyright) ? _vm.item.titelbild.copyright : _vm.item.titel,"title":(_vm.item.titelbild && _vm.item.titelbild.copyright) ? _vm.item.titelbild.copyright : _vm.item.titel,"loading":"lazy"}}):_vm._e(),_vm._v(" "),(!_vm.imageOnly)?_c('div',{staticClass:"news-teaser__body news-teaser__body--ecm"},[_c('h2',{staticClass:"news-teaser__title news-teaser__title--ecm"},[_vm._v("\n      "+_vm._s(_vm.itemTitle)+"\n    ")]),_vm._v(" "),(_vm.item.teaser)?_c('div',{staticClass:"news-teaser__text news-teaser__text--ecm",domProps:{"innerHTML":_vm._s(_vm.teaserText)}}):_vm._e(),_vm._v(" "),(_vm.button)?_c('span',{staticClass:"news-teaser__read-more",attrs:{"href":_vm.item.url
          ? _vm.item.url
          : _vm.localePath(`/${_vm.path}/${_vm.item.slug}/${_vm.item.pk_id}`)}},[_c('Chevron',{staticClass:"news-teaser__chevron"}),_vm._v("\n      "+_vm._s(_vm.$t("global.readmore"))+"\n    ")],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }