
import Vue from "vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    link: {
      type: Object,
      default: {},
    },
    image: {
      type: Object,
      default: {},
    },
    dark: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    imagewide: {
      type: Boolean,
      default: true,
    },
    action: {
      type: String,
      default: "",
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clickTeaser(e) {
      this.$emit("click");
      Vue.trackMatomoEvents(e.target, this.$matomo);
    },
  },
});
