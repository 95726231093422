
import Vue from "vue";
import { metaTags } from "~/lib/utils";

const NEWS_PER_PAGE = 6;

export default Vue.extend({
  head() {
    let title = this.$t("news.meta_title");

    let meta = {
      title: this.$t("news.meta_title"),
      description: this.$t("news.meta_description"),
      ogimage: "https://wirsindgueter.de/share-container.jpg",
    };

    if (this.$i18n.locale === "es") {
      title = this.$t("news.page.title");
      meta = {
        title: this.$t("news.meta_title"),
        description: this.$t("news.page.description"),
        ogimage: "https://wirsindgueter.de/share-container.jpg",
        keywords: this.$t("news.page.keywords"),
        site_name: this.$t("news.page.site_name"),
      };
    }

    return {
      title: title,
      meta: metaTags(meta),
    };
  },
  data() {
    return {
      news: [],
      page: 1,
      atLastPage: false,
    };
  },
  async fetch() {
    const news = await this.getNewsPage();
    this.news = news.items;
  },
  methods: {
    loadMore() {
      this.$matomo.trackEvent(
        "SelectNews",
        "LoadMore_News",
        "Weitere Beiträge laden",
      );

      this.getNewsPage().then((news) => {
        const moreNews = [...this.news];

        news.items.forEach((element) => {
          if (moreNews.findIndex((n) => n.pk_id === element.pk_id) === -1) {
            moreNews.push(element);
          }
        });

        // hide the "load more" button if there are no more news to add
        if (moreNews.length === news.count) {
          this.atLastPage = true;
        }

        this.news = moreNews;
      });
    },
    async getNewsPage(): Promise<any> {
      const news = await this.$directusApi.getNews({
        limit: NEWS_PER_PAGE,
        page: this.page,
        isPreview: this.$router.currentRoute.query.preview,
      });
      this.page++;

      return news;
    },
  },
  mounted() {
    let scrolledToBottom = false;
    window.addEventListener("scroll", () => {
      if (this.$router.currentRoute.name.startsWith("news")) {
        if (
          window.innerHeight + window.scrollY + 1 >=
            document.body.offsetHeight &&
          !scrolledToBottom
        ) {
          scrolledToBottom = true;
          this.$matomo.trackEvent("ScrollDown", "Scroll_News_2", "Seitenende");
        }
      }
    });
  },
});
