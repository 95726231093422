var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"newspage"},[_c('Section',{attrs:{"title":_vm.$t('news.title')}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-md-1 col-md-10"},[_c('div',{staticClass:"newspage__subtitle u-text-center u-mb-3 u-mb-md-10",domProps:{"innerHTML":_vm._s(_vm.$t('news.subtitle'))}})])]),_vm._v(" "),(_vm.news.length)?_c('div',{staticClass:"row"},_vm._l((_vm.news),function(item,index){return _c('div',{key:item.pk_id,staticClass:"newspage__item col-md-12"},[_c('TeaserEcm',{attrs:{"item":item,"asRow":true,"longText":true,"button":true}}),_vm._v(" "),(index !== _vm.news.length - 1)?_c('hr',{staticClass:"news-teaser__divider"}):_vm._e()],1)}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"newspage__footer d-flex justify-content-center",class:{ 'newspage__footer--hidden': _vm.atLastPage }},[_c('button',{staticClass:"btn btn--ghost",attrs:{"title":_vm.$t('news.loadmore')},on:{"click":_vm.loadMore}},[_vm._v("\n        "+_vm._s(_vm.$t("news.loadmore"))+"\n      ")])])]),_vm._v(" "),_c('Section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 offset-md-1"},[_c('LargeTeaser',{attrs:{"action":"Initiative_Teaser_News","dark":true,"title":this.$t('cargomontag.petitionteaser.title'),"text":this.$t('cargomontag.petitionteaser.text'),"image":this.$t('cargomontag.petitionteaser.image'),"color":"green","link":{
            title: this.$t('cargomontag.petitionteaser.linktitle'),
            href: _vm.localePath('/initiative'),
          }},on:{"click":function($event){return _vm.$matomo.trackEvent(
              'SelectPetition',
              'Teaser_News',
              'Mehr erfahren',
            )}}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }